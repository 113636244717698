<template>
    <content-view class="w-100">
        <div class="recommended-materials">
            <template v-if="recommendMaterials.length && recommendMaterials.length > 0">
                <div class="recommended-materials__header">
                    <emoji
                        class="mr-20"
                        emoji="books"
                        set="apple"
                        :size="32"
                    />
                    <h2 class="recommended-materials__title">
                        Статьи для Вас
                    </h2>
                </div>
                <ul class="recommended-materials__list">
                    <li
                        :key="material.id"
                        v-for="material in recommendMaterials"
                        class="recommended-materials__item"
                    >
                        <emoji
                            v-if="material.emoji.length"
                            :emoji="material.emoji"
                            set="apple"
                            :size="16"
                        />
                        <icon v-else width="15" height="18" class="recommended-materials__item-icon">
                            <svg
                                width="15"
                                height="18"
                                viewBox="0 0 15 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 0.5C0 0.223858 0.223858 0 0.5 0H14.5C14.7761 0 15 0.223858 15 0.5V17.5C15 17.7761 14.7761 18 14.5 18H0.5C0.223858 18 0 17.7761 0 17.5V0.5ZM2.99988 10.1252C2.99988 9.50392 3.50356 9.00024 4.12488 9.00024H10.8749C11.4962 9.00024 11.9999 9.50392 11.9999 10.1252C11.9999 10.7466 11.4962 11.2502 10.8749 11.2502H4.12488C3.50356 11.2502 2.99988 10.7466 2.99988 10.1252ZM4.12488 4.50012C3.50356 4.50012 2.99988 5.0038 2.99988 5.62512C2.99988 6.24644 3.50356 6.75012 4.12488 6.75012H7.87488C8.4962 6.75012 8.99988 6.24644 8.99988 5.62512C8.99988 5.0038 8.4962 4.50012 7.87488 4.50012H4.12488Z"
                                    fill="#989CAE"
                                />
                            </svg>
                        </icon>
                        <router-link
                            class="recommended-materials__link"
                            :to="`/knowledge/article/${material.id}`"
                            >{{ material.title }}</router-link
                        >
                    </li>
                </ul>
            </template>

            <template v-else>
                <loading-spinner v-if="isLoading" />
                <div v-else class="plug">
                    <div class="plug__container">
                        <icon width="160" height="160">
                            <path
                                d="M101.922 65.5417C111.148 57.1299 123.625 45.7534 124.762 18.5747H133V10.4209H27V18.5747H35.2378C36.375 45.7534 48.852 57.1299 58.0784 65.5417C64.2674 71.1844 67.7692 74.6381 67.7692 80.0004C67.7692 85.3626 64.2674 88.8163 58.0784 94.4591C48.852 102.871 36.375 114.247 35.2378 141.426H27V149.58H133V141.426H124.762C123.625 114.247 111.148 102.871 101.922 94.4591C95.7326 88.8163 92.2308 85.3626 92.2308 80.0004C92.2308 74.6381 95.7326 71.1844 101.922 65.5417ZM75.9231 110.56C74.6639 110.82 73.4198 111.198 72.2057 111.701L47.234 122.034C51.3121 111.665 57.8802 105.674 63.5719 100.485C69.9238 94.6936 75.9231 89.2237 75.9231 80.0004V110.56ZM96.4281 100.485C102.12 105.674 108.688 111.665 112.766 122.034L87.7943 111.7C86.5802 111.198 85.3362 110.82 84.0769 110.56V80.0004C84.0769 89.2237 90.0763 94.6936 96.4281 100.485ZM47.0908 37.6004C45.1195 32.4637 43.7581 26.2616 43.4017 18.5747H116.598C116.242 26.2616 114.881 32.4637 112.909 37.6004H47.0908Z"
                                fill="#E6EBF3"
                            />
                        </icon>
                        <span class="v-desc">Нет статей</span>
                    </div>
                </div>
            </template>
        </div>
    </content-view>
</template>

<script>
    import ContentView from '@/views/menu/ContentView';
    import Icon from '@/components/icon/Icon';
    import session from '@/api/session';
    import LoadingSpinner from '@/components/LoadingSpinner.vue';
    import { Emoji } from 'emoji-mart-vue';
    import { metaTitle } from '@/mixins/meta_title';

    export default {
      name: 'RecommendedMaterials',
      components: { Icon, ContentView, Emoji, LoadingSpinner },
      data() {
        return {
          recommendMaterials: [],
          isLoading: true
        };
      },
      mixins: [metaTitle],
      async created() {

        try {
            const request = await session.get('/api/v1/material/recommendations/');
            this.recommendMaterials = request.data;
        } catch (error) {
            console.error(error);

            this.$swal.fire({
                toast: true,
                title: 'При загрузке статей произошла ошибка. Попробуйте перезагрузить страницу.',
                icon: 'error',
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true
            });
        }

        this.isLoading = false;
      }
    };
</script>

<style lang="scss" scoped>
    @import "#sass/v-style";

    .recommended-materials {
        max-width: 960px;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        @include below(1280px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        &__header {
            display: flex;
            align-items: baseline;
        }

        &__list {
            margin-top: 50px;
        }

        &__item {
            display: flex;
            align-items: baseline;
            &:nth-child(n + 2) {
                margin-top: 20px;
            }
            &-icon {
                min-width: 15px;
            }
        }

        &__link {
            margin-left: 10px;
            font-size: 1.25rem;
            color: #000;
            font-weight: 600;
            &:hover {
                text-decoration: underline;
            }
        }

        &__title {
            font-size: 2.5rem;
        }
    }

    .plug {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__container {
            height: calc(100vh - 25px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 225px;
            & > span {
                margin-top: 24px;
                text-align: center;
                font-size: 24px;
                line-height: 32px;
            }
        }
    }
</style>
